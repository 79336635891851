import * as React from 'react'
import { graphql } from 'gatsby'
import EntriesPage from '../components/entries'
import { details } from '../styles/entries.module.css'

const EducationDataHandler = (data) => {
  const entryData = data.childMdx.frontmatter;
  const graduationDate = new Date(entryData.graduation);
  const graduationDateStr = `${graduationDate.toLocaleString('default', { month: 'long' })}, ${graduationDate.getFullYear()}`;
  return (
    <div className={details}>
      <p className="lead">{entryData.degree} Degree in {entryData.major}</p>
      {entryData.minor && <p className="lead">Minor in {entryData.minor}</p>}
      <p className="lead">Concentrations in {entryData.concentrations.join(', ')}</p>
      <p className="lead">Graduated {graduationDateStr}</p>
    </div>
  );
};

const EducationPage = ({ data }) => (
  <EntriesPage title="Education" entries={data.allFile.nodes} extraHandler={EducationDataHandler} />
);

export const query = graphql`
{
    allFile(
      filter: {sourceInstanceName: {eq: "education"}}
      sort: {order: DESC, fields: childrenMdx___frontmatter___graduation}
    ) {
      nodes {
        childMdx {
          body
          slug
          frontmatter {
            title
            degree
            major
            minor
            concentrations
            graduation
          }
        }
      }
    }
  }  
`;

export default EducationPage
